/* global window */
// @flow
import React, { useState, useCallback, } from 'react';
import Head from 'next/head';
import type { Node, } from 'react';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import useDocumentEventListener from '../../hooks/useDocumentEventListener';


const scriptIds = Object.freeze({
  htz: '283396bd-1cd4-4564-aa15-d5d1d58f4477',
  tm: 'ce4c1c85-fbe2-4304-87ff-9ee040a3dbbb',
  hdc: 'eb22baca-8600-4c9f-9593-e39b553b5cdb',
});

const { siteAlias, } = checkSiteFromConfig();

const hdcAdvs = `
'16775594': true,
'4617984126': true,
'19382954': true,
'4594759435': true,
'4866015999': true,
'5157057779': true,
'4821042148': true,
'4512807560': true,
'5271608819': true,
'5650054233': true,
'4646761194': true,
'5481299939': true,
'4980930905': true,
'4731188789': true,
'5177775788': true,
'5651586698': true
`;

const defaultAdvs = `
'16775594': true,
'4617984126': true,
'19382954': true,
'4594759435': true,
'4866015999': true,
'5157057779': true,
'4821042148': true,
'4512807560': true,
'5157418500': true,
'4980930905': true,
'4731188789': true,
'5177775788': true,
'5271608819': true,
'23099114': true,
'5324115503': true,
'5208177348': true,
'4606717621': true,
'5364560761': true,
'5117068771': true
`;

function GeoEdge(): Node {
  const scriptId = scriptIds[siteAlias];
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);

  const onLoadElement = useCallback(e => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  if (!scriptId || (!isLoaded && isWebView)) return null;

  const scriptSrc = `//rumcdn.geoedge.be/${scriptId}/grumi-ip.js`;

  const advsConfig = siteAlias === 'hdc' ? hdcAdvs : defaultAdvs;

  return (
    <Head>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          window.grumi = {
            cfg: {
              advs: { ${advsConfig} },
            },
            key: '${scriptId}'
          };
          `,
        }}
      />
      <script src={scriptSrc} async />
    </Head>
  );
}

export default GeoEdge;
